/*定义正则*/
/**
 * vant的validator验证方法第二个参数会是一个对象，故一些方法需要多个参数时会运用对象进行解构
 */
const Regular = {
  // 验证手机号码格式是否正确
  isTelenum(val) {
    let reg = /^[1][0-9]{10}$/;
    return reg.test(val) ? true : false
  },
  // 邮箱正则
  isEmail(val) {
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(val) ? true : false
  },
  // 身份证正则
  isIdentity(val) {
    let reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
    return reg.test(val) ? true : false
  },
  // 银行卡号
  isBank(val) {
    let reg = /^\d{16,19}$/;
    return reg.test(val)
  },
  // QQ号 ，qq号是从10000开始的
  isQQ(val) {
    let reg = /^[1-9][0-9]{4,}$/
    return reg.test(val) ? true : false
  },
  // 去除所有空格
  removeAllSpace(str) {
    return str.replace(/\s+/g, "");
  },
  // 去除前后空格
  trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
  },
  // 验证字符是否是数字
  isNumber(val) {
    // 第一个数字非0情况
    let reg1 = /^([-+])?[1-9][0-9]*(\.\d+)?$/;
    // 小数0-1之间的情况
    let reg2 = /^([-+])?0(\.\d+)?$/
    return (reg1.test(val) || reg2.test(val)) ? true : false
  },
  // 整数,包含m-n位整数,first0控制001的情况,include0包含数字0
  isInteger(val, { min = 0, max = '', first0 = false, include0 = true } = {}) {
    if (first0) {
      let _params = `{${min},${max}}`
      let reg = new RegExp(`^[0-9]${_params}$`);
      return reg.test(val) ? true : false
    }
    // 只有1位数字的判断
    if (max === 1 || val.toString().length === 1) {
      let _params = `{${min},${max}}`
      let reg = include0 ? new RegExp(`^[0-9]${_params}$`) : new RegExp(`^[1-9]${_params}$`)
      return reg.test(val) ? true : false
    }
    let _max = max ? max - 1 : max
    let _min = min ? min - 1 : min
    let _params = `{${_min},${_max}}`
    let reg = new RegExp(`^[1-9][0-9]${_params}$`);
    return reg.test(val) ? true : false
  },
  // 验证数字是否包含m-n位小数
  isDecimal(val, { min = 0, max = '' } = {}) {
    let params = `{${min},${max}}`
    // 第一个数字非0情况
    let reg1 = new RegExp(`^([-+])?[1-9][0-9]*\\.\\d${params}$`);
    // 小数0-1之间的情况
    let reg2 = new RegExp(`^([-+])?0\\.\\d${params}$`);
    // 第一个数字非0情况
    return (reg1.test(val) || reg2.test(val)) ? true : false
  },

  //中文m-n位
  isCn(val, { min = 0, max = '' } = {}) {
    let params = `{${min},${max}}`
    let reg = new RegExp(`^[\u4e00-\u9fa5]${params}$`);
    return reg.test(val) ? true : false
  },
  //英文m-n位
  isEn(val, { min = 0, max = '' } = {}) {
    let params = `{${min},${max}}`
    let reg = new RegExp(`^[A-Za-z]${params}$`);
    return reg.test(val) ? true : false
  },

  //只包含英文和汉字m-n位
  iszimuhanzi(val, { min = 0, max = '' } = {}) {
    let params = `{${min},${max}}`
    let reg = new RegExp(`^[a-zA-Z\u4e00-\u9fa5]${params}$`);
    return reg.test(val) ? true : false
  },


  //英文和正整数m-n
  isEnAndNumber(val, { min = 0, max = '' } = {}) {
    let params = `{${min},${max}}`
    let reg = new RegExp(`^[A-Za-z0-9]${params}$`);
    return reg.test(val) ? true : false
  },
  //英文汉字和正整数m-n
  isEnAndCnAndNumber(val, { min = 0, max = '' } = {}) {
    let params = `{${min},${max}}`
    let reg = new RegExp(`^[A-Za-z0-9\u4e00-\u9fa5]${params}$`);
    return reg.test(val) ? true : false
  },
  // 验证是否包含表情符号和特殊字符
  isGFString(value) {
    // let pattern = new RegExp("[`~%!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
    // 英文字符
    let regEn = /[`~#$^&*_+<>{}\\/[\]]/im
    // 中文字符
    let regCn = /[·#￥——《》【】[\]]/im
    // 字符表情
    let faceImg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]+/g
    if (regEn.test(value) || regCn.test(value) || faceImg.test(value)) {
      return true
    } else {
      return false
    }
  },
};
export default Regular;
