<template>
  <div class="container">
    <nav-bar header-title="超鹿锦鲤" :show-back="true" :header-back="newAppBack" />
    <img src="https://img.chaolu.com.cn/ACT/anniversary-2024/koi/head.png" alt="" class="banner" />
    <div class="content">
      <div class="q-item">
        <p class="p1 f30"><em class="red">*</em> 三个月内的健身目标</p>
        <van-field class="text-area" v-model="params.fitnessGoal" type="textarea" placeholder="请输入内容..." show-word-limit maxlength="50" autosize />
      </div>
      <div class="q-item">
        <p class="p1 f30">当前健身难点</p>
        <van-field class="text-area" v-model="params.fitnessDifficulty" type="textarea" placeholder="请输入内容..." show-word-limit maxlength="50" autosize />
      </div>
      <div class="q-item">
        <p class="p1 f30">当前本人身材照</p>
        <upload @success="uploadSuccess"></upload>
      </div>
    </div>

    <div class="buttons col-start-center">
      <p class="p1 f22">* 以上内容仅作为活动信息收集，不会对外展示及发布</p>
      <div :class="['sd-button', 'flex-auto', 'f32', 'fw6', 'row-center-center', !trims(params.fitnessGoal) ? 'disabled' : '']" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import Regular from '@/lib/regular'
import navBar from '@/components/nav-bar/nav-bar'
// import upload from '@/components/upload/index'

export default {
  mixins: [userMixin],
  components: {
    navBar,
    upload: () => {
      return import(`@/components/upload/index`);
    }
  },
  data() {
    return {
      params: {
        fitnessGoal: '',
        fitnessDifficulty: '',
        figurePhotoUrl: '',
      },
    }
  },
  async created() {
    await this.$getAllInfo()
    initBack()
  },
  methods: {
    newAppBack,

    uploadSuccess(arr) {
      this.params.figurePhotoUrl = arr[0]
    },
    submit() {
      if (!this.trims(this.params.fitnessGoal)) return

      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.$axios
        .post(`${this.baseURLApp}/anniversary/sixth/preheat/fillWishForm`, {...this.params, userId: this.userId, token: this.token})
        .then((res) => {
          this.$toast('提交成功！')
          setTimeout(() => {
            this.newAppBack()
          }, 3000)
        })
        .catch((r) => {
          this.$toast(r.data.msg)
        })
    },
    trims(str) {
      return Regular.trim(str)
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
  .banner {
    width: 100%;
  }
  .content {
    padding: 0 32px 320px;
    margin: -174px 0 0;
    .q-item {
      margin: 42px 0 0;
      .p1 {
        margin: 0 0 18px;
      }
      .text-area {
        background-color: #f5f5f5;
        border-radius: 8px;
      }
    }
  }
  .buttons {
    width: 100%;
    padding: 24px 32px calc(24px + constant(safe-area-inset-bottom));
    padding: 24px 32px calc(24px + env(safe-area-inset-bottom));
    position: fixed;
    left: 0;
    bottom: 0;
    background: #ffffff;
    box-shadow: inset 0px 1px 0px 0px rgba(238, 238, 238, 1);
    .p1 {
      color: #68413c;
      margin: 0 0 12px;
    }
  }
  .red {
    color: #f50606;
  }
}
</style>
